import React from 'react'
import FourZeroFourView from 'views/404-view'
import JobsView from 'views/jobs-view'
import { fetchAllJobRequisitions, fetchJobFilters } from 'utils/sanity/job-requisitions'

const CareerAreaJobsPage = props => {
  const {
    serverData: { careerArea, error, jobFilters, jobRequisitions },
  } = props

  if (error) {
    return <FourZeroFourView />
  }

  return (
    <JobsView careerArea={careerArea} jobFilters={jobFilters} jobRequisitions={jobRequisitions} />
  )
}

export const getServerData = async context => {
  const {
    params: { careerArea },
  } = context

  try {
    const [jobRequisitions, jobFilters] = await Promise.all([
      fetchAllJobRequisitions({ careerArea }),
      fetchJobFilters(),
    ])

    if (jobFilters.careerAreas.find(obj => obj.handle === careerArea)) {
      return {
        props: {
          careerArea,
          jobFilters,
          jobRequisitions,
        },
      }
    }

    return {
      props: {
        error: true,
      },
    }
  } catch (error) {
    console.error(error)
    return {
      props: {
        error: true,
      },
    }
  }
}

export default CareerAreaJobsPage
